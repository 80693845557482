(function ($) {
  Drupal.behaviors.selectLanguageV1 = {
    attach: function (context, settings) {
      $('.language a').bind('click', function (event) {
        event.preventDefault();
        prefix = $(this).attr('data-mp-lang');
        var postfixes = $(this).parent().attr('data-mp-base-lang');
        var countryFlag = $(this).parent().attr('data-mp-country');

        return switchLanguage(prefix, postfixes, countryFlag);
      });

      function switchLanguage(lang, postfixesdata, countryFlag) {
        eval('var postfixes=' + postfixesdata);
        var locale_cookie = lang + '_' + countryFlag;

        // only switch if not already in requested language
        if (locale_cookie != generic.cookie('LOCALE')) {
          var lang2 = $('.footer-language-select__link02').attr('data-mp-lang');
          var lang3 = $('.footer-language-select__link03').attr('data-mp-lang');
          var lang2_val = postfixes[lang2];
          var lang3_val = postfixes[lang3];
		    var path = window.location.pathname.replace(lang2_val, postfixes[lang]);

		    if (path == window.location.pathname) {
		    	path = window.location.pathname.replace(lang3_val, postfixes[lang]);
		    }
		    document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
		    generic.cookie('LOCALE', locale_cookie, { path: '/' });

		    var pathArray = path.split('/');
		    var postfixesArray = postfixes[lang].split('/');

		    if (pathArray[1] == postfixesArray[1]) {
		        var site_path = window.location.host + path;
		    } else {
		        var site_path = window.location.host + postfixes[lang] + path;
		    }

		    site_path = site_path.replace(/\/+/g, '/');
		    var url = window.location.protocol + '//' + site_path;
		    var full_url = window.location.href;
		    var appends = '';

		    if (full_url.indexOf('#') > 0) {
		      var full_url_parts = full_url.split('#');

		      appends = '#' + full_url_parts[1];
		    } else if (full_url.indexOf('?') > 0) {
		      var full_url_parts = full_url.split('?');

		      appends = '?' + full_url_parts[1];
		    }
		    window.location.href = url + appends;
        }
      }
    }
  };
})(jQuery);
